// custom-list
.custom-list {
	padding: 0;
	list-style: none;

	li {
		position: relative;
		padding: 0 0 0 1.5rem;

		&::before {
			content: "\f00c";
			position: absolute;
			top: 0;
			left: 0;
			font-family: $font-awesome;
			color: $primary;
			font-weight: 900;
		}
	}
}

// custom-list-extra
.custom-list-extra {
	padding: 0;
	list-style: none;

	li {
		position: relative;
		padding: 0 0 0 1.5rem;

		&::before {
			content: "\f058";
			position: absolute;
			top: 0;
			left: 0;
			font-family: $font-awesome;
			color: $primary;
			font-weight: 400;
		}
	}
}

// usp-list
ul.usps-list {
	display: flex;
	gap: 0.5rem;
	li,
	a {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		padding-bottom: 1.5rem;
		border-bottom: 1px solid $gray-dark;
		text-decoration: none;
		i {
			order: 1;
			font-size: 80px;
			color: $pink;
			transition: 0.35s ease;
		}
		span {
			display: flex;
			flex-flow: column;
			.list-item-title {
				color: $gray-dark;
				font-size: 48px;
				font-weight: 600;
			}
			.list-item-short-description {
				font-size: 28px;
				font-weight: 600;
				margin-top: -0.75rem;
				color: $black;
			}
		}
		.list-item-suffix {
			display: none;
		}
		&:hover {
			i {
				font-weight: 900;
				transform: scaleX(-1);
				transition: 0.35s ease;
				&::before {
				}
			}
		}
		&:last-child {
			border-bottom: none;
		}
		@include media-breakpoint-down(md) {
			padding-right: 5px;
			.list-item-title {
				font-size: 26px !important;
				max-width: 92% !important;
				overflow: hidden;
				word-wrap: break-word;
				word-break: keep-all;
				line-height: 26px;
			}
			.list-item-short-description {
				font-size: 20px !important;
				margin-top: auto !important;
				max-width: 95% !important;
			}
			i {
				font-size: 48px !important;
			}
		}
	}
	a {
		border-bottom: 0 !important;
	}
}
