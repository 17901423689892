// bg-*
// =========================================================================
section {
	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 6vh 0;
	}
}

.lead-section + .content-section,
.lead-section + .bundle-overview-section {
	margin-top: calc(-6vh + 30px);
}
// home-intro-section
.home-intro-section {
	background: $white;
	padding: 6vh 0;
	.title-container {
		.title {
			font-size: 48px;
			max-width: 95%;
			@include media-breakpoint-down(md) {
				font-size: 32px;
				overflow: hidden;
				word-wrap: break-word;
				max-width: 100%;
			}
		}
	}
	.container-two-columns {
		.one {
			max-width: 75%;
			flex-basis: 75%;
			.description {
				margin: 1rem auto;
				p {
					font-size: 28px;
					font-weight: 600;
					@include media-breakpoint-down(md) {
						font-size: 22px;
					}
				}
			}
		}
		.two {
			max-width: 25%;
			flex-basis: 25%;
			.map-image {
				position: relative;

				&::after {
					content: "\f649";
					font-family: $font-awesome;
					position: absolute;
					left: 55px;
					top: 37px;
					font-size: 48px;
					color: $pink;
					transition: 0.35s ease-out;
				}
				&:hover {
					&::after {
						transform: scale(1.3) rotate(30deg);
						transition: 0.35s ease-in;
					}
				}
			}
			@include media-breakpoint-down(md) {
				justify-content: center;
				align-items: center;
				margin-top: 3vh;
			}
		}
		@include media-breakpoint-down(md) {
			.column {
				max-width: 100%;
				flex-basis: 100%;
			}
		}
	}
}

// accommodation-section
.accommodation-section {
	padding: 15vh 0;
	.acco-masonry {
		.card-columns {
			column-count: 2;
			.item {
				.card {
					position: relative;
					.card-image {
						filter: brightness(0.9);
						&::after {
							content: "";
							position: absolute;
							top: 50%;
							left: 50%;
							width: 4px;
							height: 4px;
							background: $pink;
							transition: 0.25s ease-out;
							opacity: 0;
							transform: translate(-50%, -50%);
							border-radius: 50%;
						}
					}
					.card-body {
						position: absolute;
						top: 0;
						padding: 1.5rem;
					}
					.card-title {
						color: $white;
						font-size: 48px;
					}
					.card-subtitle {
						font-size: 28px;
						color: $white;
					}

					.card-description {
						opacity: 0;
						color: $white;
					}
					&:hover {
						.card-subtitle,
						.card-description {
							opacity: 1;
							transition: 0.25s ease;
						}
						.card-image {
							&::after {
								content: "";
								position: absolute;
								width: 50rem;
								height: 50rem;
								background: $pink;
								transition: 0.5s ease-in;
								opacity: 0.8;
								aspect-ratio: 1/1;
							}
						}
					}
					@include media-breakpoint-down(md) {
						.card-title {
							font-size: 30px !important;
						}
						.card-subtitle {
							font-size: 20px;
						}
					}
				}
				&:nth-child(1) {
					margin-top: -9vh;
					.card {
						background: none;
						border: none;
						box-shadow: none;
						.card-body {
							position: relative;
						}
						.card-image {
							picture {
								display: none;
							}
						}
						.card-title {
							font-size: 100px;
							font-weight: 600;
							color: $black;
							&:hover {
								color: $black;
							}
						}
						.card-subtitle {
							display: block;
							font-size: 28px;
							opacity: 1;
							color: $black;
						}
						.card-description {
							display: block;
							opacity: 1;
							color: $black;
						}
					}
					@include media-breakpoint-down(md) {
						margin-top: auto;
						.card-title {
							font-size: 60px !important;
						}
					}
				}
			}
			@include media-breakpoint-down(md) {
				column-count: 1;
			}
		}
	}
	.usp-container {
		div.usps-list {
			margin-top: 3vh;
		}
	}
	.links-container {
		.column {
			display: flex;
			flex-flow: row;
			gap: 1rem;
			@include media-breakpoint-down(md) {
				flex-flow: row wrap;
			}
			.link {
				max-width: max-content;

				a {
					@extend .btn, .btn-secondary;
				}
			}
		}
	}
	@include media-breakpoint-down(md) {
		padding: 6vh 0;
	}
}
// cta section
.cta-section {
	background: $pink;
	padding: 12vh 0;
	.one {
		max-width: 70% !important;
		flex-basis: 70% !important;
		.title,
		.subtitle,
		.undertitle {
			color: $white;
		}
		.title {
			font-size: 48px;
			font-weight: 600;
		}
		.subtitle {
			font-size: 28px;
		}
		.undertitle {
			font-weight: 600;
		}
	}
	.two {
		max-width: 30% !important;
		flex-basis: 30% !important;

		display: flex;
		align-items: center;
		justify-content: center;

		a {
			@extend .btn, .btn-white;
			padding: 1.75rem 3.25rem !important;
		}
	}
	@include media-breakpoint-down(md) {
		padding: 9vh 0;
		.container-two-columns {
			.container-holder {
				flex-flow: column;
				.one,
				.two {
					max-width: 100% !important;
					flex-basis: 100% !important;
				}
				.one {
					.title {
						font-size: 28px !important;
					}
					.subtitle {
						font-size: 20px !important;
					}
				}
				.two {
					justify-content: flex-start;
					align-items: flex-start;
					a {
						padding: 1.75rem 2.5rem;
					}
				}
			}
		}
	}
}
// eyecatcher-content-section
.eyecatcher-content-section {
	margin-bottom: 6vh;
	.container-fluid {
		.column {
			padding: 0 !important;
		}
	}
	.links-container {
		margin-top: 2rem;
		.column {
			display: flex;
			flex-flow: row;
			gap: 1rem;

			.link {
				max-width: max-content;

				a {
					@extend .btn, .btn-secondary;
				}
			}
			@include media-breakpoint-down(md) {
				flex-flow: row wrap;
			}
		}
	}
}
// lead-section
// =========================================================================
.lead-section {
	margin: 6vh 0;

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}

	.info {
		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;

			@extend .list-unstyled;

			li {
				margin: 0 15px 0 0;
			}

			.icon {
				margin-right: 5px;
			}
		}

		// assortiment
		.former-price {
			font-size: $font-size-sm;
			text-decoration: line-through;
		}

		.discount-price {
			font-size: $font-size-sm;

			.amount {
				font-size: $font-size-lg;
			}

			margin-bottom: 1rem;
		}

		.price {
			margin-bottom: 1rem;

			.amount {
				font-size: $font-size-lg;
			}
		}
	}
}

// content-section
// =========================================================================
.content-section {
	margin: 6vh 0;
}

// collection-section
// =========================================================================
.collection-section {
	margin: 6vh 0;
}

// banner-section
// =========================================================================
.banner-section {
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin: 6vh 0;

	// collection
	.collection {
		.card {
			@extend .default-card !optional;
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 6vh 0;
}

// gallery-section
// =========================================================================
.gallery-section {
	margin-top: calc(-6vh + 30px);
	margin-bottom: calc(-6vh + 30px);
}

// booking-section
// =========================================================================
.booking-section {
	margin: 6vh 0;

	h2 {
		margin-bottom: 30px;
	}
}

// contact-section
// =========================================================================
.contact-section {
	margin: 6vh 0;
}
