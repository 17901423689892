// header
.header {
	z-index: 1000;
	position: relative;
	top: 0;
	left: 0;
	right: 0;
	background: transparent;
	transition: 0.5s;

	&.sticky {
		z-index: 1000;
		.container-fluid.bottom {
			background: $pink;
			top: 0;
			opacity: 0;
			transition: 0.5s ease;
		}
	}

	.container,
	.container-fluid:not(.menu-container) {
		max-width: 100vw;
		.container-holder {
			max-width: 1140px;
			margin: auto;
			> .column {
				align-items: center;

				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
				}

				@extend .navbar-expand-xl;
			}
		}
		&.top {
			background: $pink;
			min-height: 6vh;

			.column {
				align-items: flex-start;
				padding-top: 10px;
				@include media-breakpoint-down(md) {
					flex-flow: row !important;
					padding-right: 0;
					padding-left: 0;
					z-index: 1000 !important;
				}
			}
		}
		&.bottom {
			background: $white;
			min-height: 6.1vh;
			z-index: 1000;
			.column {
				justify-content: flex-end;
				align-items: center;
				height: 6vh;
				@include media-breakpoint-down(md) {
					flex-flow: row !important;
					padding-left: 0 !important;
					padding-right: 0 !important;
				}
			}
			.logo,
			.logo img {
				margin: 5px auto 0 0;
				max-height: 3vh;
				opacity: 0;
				transition: 0.5s ease;
				display: flex;
				@include media-breakpoint-down(lg) {
					margin: 0 0 !important;
				}
			}
			&.sticky {
				.logo,
				.logo img {
					opacity: 1;
					transition: 0.5s ease;
				}
				position: fixed;
				background: $pink;
				top: 0;
				opacity: 1;
				transition: 0.5s ease;
				* {
					color: $white;
				}
				.navbar-toggler:hover {
					span,
					i {
						color: $white !important;
					}
				}
			}
			div.book-button {
				height: 100%;
				display: flex;
				align-items: center;
				ul {
					display: flex;
					gap: 1rem;
					li {
						i::before {
							min-width: 30px;
							display: block;
						}
						a {
							font-size: 20px;
							color: $gray-dark;
							text-transform: lowercase;
							font-weight: 600;
							text-decoration: none;
							display: flex;
							align-items: center;
							gap: 0.15rem;
						}
						&:hover {
							a {
								color: $pink;
							}
							i {
								font-weight: 700;
							}
						}
					}
				}
				@include media-breakpoint-down(md) {
					display: none;
				}
			}
		}
	}

	// menu-toggle
	.menu-toggle {
		margin-right: 2rem;
		z-index: 1000 !important;
		.navbar-toggler {
			align-items: center;
			margin-left: 0;
			padding: 0;
			border: none;
			color: $gray-dark;
			transition: 0.5s;
			span.navbar-toggler-label {
				font-size: 20px;
				text-transform: lowercase;
				font-weight: 600;
			}
			i {
				width: 40px;
				font-size: 24px;
				line-height: 40px;
				text-align: center;
				transition: transform 0.5s;
				&::before {
					content: "\f036";
				}
			}

			&[aria-expanded="true"] {
				i {
					transform: rotate(180deg);

					&::before {
						content: "\f00d";
						font-weight: 900;
					}
				}
				@include media-breakpoint-down(md) {
					z-index: 1000;

					span.navbar-toggler-label {
					}
					i {
						transition-delay: 1s;
					}
				}
			}
			&:hover {
				* {
					color: $pink;
				}
			}
		}
		@include media-breakpoint-down(md) {
			margin-right: 0;
		}
	}

	// logo
	.logo {
		width: 350px;
		margin: 4vh 10px 10px 0;

		a {
			@extend .d-block;

			img {
				@extend .w-100;
			}
		}
		@include media-breakpoint-down(md) {
			width: 261px;
		}
	}

	// menu
	.menu-container {
		position: fixed;
	}
	.menu {
		@include media-breakpoint-up(lg) {
			position: absolute;
			top: 0;
			left: 0;
			margin: 0;
			width: max-content;
			height: 100%;
			display: flex;
			align-items: center;

			z-index: 1000;
			&.sticky {
				bottom: 6vh;
				left: auto;
				right: 0;
				background: $pink;
				.dropdown-toggle::after {
					color: $white !important;
				}
				.dropdown-menu.show {
					background: $pink;
				}
			}
			.navbar-nav {
				display: flex;
				flex-flow: row;
				.nav-item {
					margin-right: 1rem;
					&.active {
						.nav-link {
							color: $pink;
						}
					}
				}
				.nav-link {
					color: $gray-dark;
					font-size: 16px;
					font-weight: 600;
					padding: 5px 0 !important;
					&:hover {
						color: $pink;
						border-bottom: 1px solid $pink;
					}
				}
				.dropdown-menu.show {
					box-shadow: none;
					border: none;
					padding: 1rem;
width: max-content!important;
					&::after,
					&::before {
						display: none;
					}
					.nav-link {
						&:hover {
							color: $pink;
							border-bottom: 0 !important;
						}
					}
				}
			}
		}
		@include media-breakpoint-down(md) {
			position: absolute;
			left: -15px;
			top: 0;
			background: $white;
			width: 100vw;
			z-index: 100;
			&.sticky {
				top: 6vh;
				.nav-item,
				.nav-link,
				.menu-title {
					color: $pink !important;
				}
			}
			.navbar-nav {
				flex-flow: column;
				gap: 0.35rem;
				margin: 12vh 1.5rem;
				.nav-item {
					max-width: 85%;
					&.dropdown {
						&.show {
							.nav-link {
								.dropdown-toggle::after {
									transform: scaleY(-1);
								}
							}
						}
					}
					.dropdown-toggle::after {
						display: unset !important;
					}
					.nav-link {
						font-size: 20px !important;
						display: flex;
						align-items: center;
						color: $pink !important;
					}

					.dropdown-menu {
						display: none !important;
						&.show {
							position: static !important;
							transform: none !important;
							margin: 0 !important;
							border: 0 !important;
							box-shadow: none !important;
							display: block !important;

							&::after,
							&::before {
								display: none;
							}

							.nav-item {
								margin: 0;
								border-bottom: 0;

								.nav-link {
									font-size: 16px !important;
									font-weight: 600;
									color: $black;
									padding: 0;
									border-bottom: 0;

									&:hover {
										color: $pink;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	// header-contact
	div.header-contact {
		margin-left: auto;
		margin-right: 2rem;

		ul {
			display: flex;
			gap: 1rem;
			li {
				display: flex;
				gap: 0.25rem;
				i {
					transition: 0.35s ease;
					font-size: 18px;
				}
				a {
					color: $white;
					font-weight: 600;
					text-decoration: none;
					display: flex;
					gap: 0.35rem;
					align-items: center;
				}
				&:hover {
					i {
						font-weight: 900;
						transform: rotate(360deg);
						transition: 0.35s ease;
					}
				}
			}
		}
		@include media-breakpoint-down(md) {
			margin-right: 0.85rem;
			ul {
				display: flex;
				flex-flow: row !important;
				gap: 0.75rem;
				li {
					span {
						display: none;
					}
				}
			}
		}
	}

	// site switcher
	.site-switcher {
		@include media-breakpoint-down(md) {
			ul {
				display: flex;
				flex-flow: row nowrap !important;
				li {
					min-width: 1.3rem;
				}
			}
		}
		ul {
			li.nav-item:has(img[alt="de"]) {
				order: 2 !important;
			}
		}
	}
}
