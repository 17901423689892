// mini-sab
.mini-sab {
	background: $blue-light;
	// overflow: hidden;
	.container {
		position: relative;
		@extend .d-flex;
		@extend .justify-content-center;

		.container-holder {
			z-index: 100;
			position: relative;
			margin: 15px 0 0 0;
			padding: 15px 0;
			border-radius: 4px;
			background: transparent;
			color: #fff;

			// below eyecatcher
			@include media-breakpoint-up(lg) {
				margin-top: 0;
				padding-left: 30px;
				padding-right: 30px;
			}

			// above eyecatcher
			/* @include media-breakpoint-up(lg) {
				position: absolute;
				left: auto;
				right: auto;
				bottom: 0;
			} */
		}
	}
	&.sticky {
		position: fixed;
		top: 6vh;
		width: 100%;
		z-index: 998;
		@include media-breakpoint-down(md) {
			position: relative;
		}
	}
	.filters {
		max-width: 1140px !important;
		width: 90vw !important;
		display: flex !important;
		justify-content: space-between !important;
		margin: auto;
		@include media-breakpoint-down(md) {
			max-width: 100% !important;
		}
		.filter-element {
			border-right: 1px dotted $gray;
			&.filter-context-button {
				border-right: 0 !important;
			}
		}
		@include media-breakpoint-down(md) {
			.filter-element {
				border-bottom: 1px dotted $gray;
				border-right: 0;
				&.filter-context-button {
					border-bottom: 0 !important;
				}
			}
		}
	}

	.btn-toggle-mini-sab {
		@extend .btn;
		@extend .btn-primary;
		display: block;
		width: max-content;
		margin: 2rem auto 0;
	}
}
