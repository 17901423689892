// footer
.footer {
	background: $gray;
	margin-top: 6vh;
	.footer-logo {
		background: $white;
		padding-top: 20vh;
		.image {
			display: flex;
			justify-content: center;
		}
	}
	.footer-contact {
		padding: 6vh 0;
		@include media-breakpoint-down(md) {
			padding: 6vh 0 1rem 0;
		}
		.title {
			* {
				color: $white;
				font-size: 100px;
				@include media-breakpoint-down(md) {
					font-size: 60px;
				}
			}
		}
		.contact-info {
			color: $white;
			font-weight: 400;
			font-size: 20px;
			margin-top: 2rem;
		}
		div.vocabulary-term-list {
			ul {
				display: flex;
				gap: 2rem;
				@include media-breakpoint-down(md) {
					flex-flow: column;
					gap: 0.254rem;
					margin: 2rem auto;
				}
				li {
					display: flex;
					align-items: center;
					a {
						color: $white;
						font-weight: 600;
						font-size: 20px;
						text-decoration: none;
						display: flex;
						align-items: center;
						&:hover {
							i {
								transform: translateY(-3px);
								transition: 0.35s ease;
							}
						}
						span {
							text-decoration: underline;
						}
						i {
							margin-right: 0.5rem;
							transform: translateY(2px);
							transition: 0.35s ease;
						}
					}
				}
			}
		}
		div.scroll-to-top {
			display: block;
			padding: 1rem;
			position: absolute;
			bottom: 0;
			right: 0;
			z-index: 999;

			a {
				max-width: 0px;
				display: block;
				overflow: hidden;
				color: $white;

				&::after {
					content: "\f063";
					font-family: $font-awesome;
					position: absolute;
					font-size: 88px;
					bottom: -9vh;
					transition: 0.35s ease;
					transform: scaleY(-1);
				}

				&:hover {
					&::after {
						transform: scaleY(-1) translateY(0.5rem);
						transition: 0.35s ease;
					}
				}
				@include media-breakpoint-down(md) {
					&::after {
						bottom: -3vh;
						font-size: 60px;
					}
				}
			}
			@include media-breakpoint-down(md) {
				right: 12vw;
			}
		}
	}
	.footer-socket {
		background: $body-bg;
		padding: 1rem 0;
		.list {
			display: flex;
			li {
				a {
					color: $gray-dark;
					font-size: 20px;
					text-decoration: none;
					&:hover {
						text-decoration: underline;
					}
				}
				&::after {
					content: "|";
					margin-left: 1rem;
					margin-right: 1rem;
				}
				&:last-child {
					&::after {
						display: none;
					}
				}
			}
			@include media-breakpoint-down(md) {
				flex-flow: column;
				gap: 0.5rem;
				li {
					a {
						font-size: 16px;
					}
					&::after {
						display: none;
					}
				}
			}
		}
	}
}
