// eyecatcher
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 50vh;
	min-height: 300px;
	max-height: 540px;
	.owl-subtitle,
	.owl-btn,
	.owl-description,
	.owl-dots,
	.owl-nav {
		display: none;
	}
	.owl-caption-holder {
		height: 100%;
		display: flex;
		align-items: flex-end;
		justify-content: flex-start;
		padding-bottom: 2.5rem;
		.owl-title {
			text-align: left;
			font-size: 100px;
			font-weight: 600;
			@include media-breakpoint-down(md) {
				font-size: 60px !important;
			}
		}
	}
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: 65vh;
	min-height: 540px;
	max-height: 1080px;
}

.content-eyecatcher {
	item {
		padding: 0 !important;
	}
	.owl-item {
		min-height: 80vh;
	}
	margin-bottom: 6vh;
	.owl-subtitle,
	.owl-btn,
	.owl-description,
	.owl-dots,
	.owl-nav {
		display: none !important;
	}

	.owl-caption-holder {
		height: 100% !important;
		display: flex !important;
		align-items: flex-end !important;
		justify-content: flex-start !important;

		.owl-title {
			text-align: left !important;
			font-size: 100px !important;
			font-weight: 600 !important;
			@include media-breakpoint-down(md) {
				font-size: 60px !important;
			}
		}
	}
}

section.eyecatcher {
	position: relative;
	div.scroll-down-btn {
		display: flex;
		justify-content: flex-end;
		padding: 1rem;
		position: absolute;
		max-width: 1140px;
		margin-left: auto;
		margin-right: auto;
		left: 0;
		bottom: 0;
		right: 0;
		z-index: 999;
		a {
			max-width: 0px;
			display: block;
			overflow: hidden;
			color: $white;
			&::after {
				content: "\f063";
				font-family: $font-awesome;
				position: absolute;
				font-size: 88px;
				bottom: 3vh;
				transition: 0.35s ease;
				transform: translateX(-100%);
			}
			&:hover {
				&::after {
					transform: translateX(-100%) translateY(0.5rem);
					transition: 0.35s ease;
				}
			}
		}
		@include media-breakpoint-down(md) {
			bottom: -3vh;
			right: 1.5rem;
			a {
				&::after {
					font-size: 60px;
				}
			}
		}
	}
	div.usps-icon {
		max-width: 1140px;
		margin-left: auto;
		margin-right: auto;
		left: 0;
		position: absolute;
		top: 15px;
		height: 25%;
		right: 0;
		z-index: 999;
		ul {
			margin-top: 1rem;
			justify-content: flex-end;
			display: flex;
			margin-right: 1rem;
			gap: 1rem;
			li {
				position: relative;
				.list-item-title {
					background: $white;
					padding: 0.55rem 1rem;
					border-radius: 6px;
					position: absolute;
					bottom: -1rem;
					width: max-content;
					right: 0;
					transform: translate(0%, 100%) scale(0);
					transition: 0.25s ease-in;

					&::before {
						content: "";
						position: absolute;
						top: -9px;
						right: 12px;
						margin-left: -6px;
						border-width: 0 6px 9px 6px;
						border-style: solid;
						border-color: transparent transparent $white transparent;
					}
					&.show {
						transform: translate(0%, 100%) scale(1);
						transition: 0.25s ease-in;
					}
				}
				i {
					font-size: 45px;
					color: $white;
				}
				&:hover {
					cursor: pointer;

					i {
						font-weight: 700;
					}
				}
			}
		}
		@include media-breakpoint-down(md) {
			right: 15px;
			ul {
				li {
					i {
						font-size: 24px;
					}
				}
			}
		}
	}
}


